<script>
import numberedPropsToArray from '@/utils/numbered-props-to-array'
import { get } from 'vuex-pathify'
import appbarHeightMixin from '@/v-shop/mixins/appbarHeightMixin'
import hooksMixin from '@/v-shop/mixins/hooks-mixin'

export default {
	mixins: [appbarHeightMixin, hooksMixin('CategoriesMegaMenu')],
	props: {
		noBg: Boolean,
		hamburgerBtn: Boolean,
		dense: {
			type: Boolean,
			default: false,
		},
		denseWidthScale: {
			type: Number,
			default: 0.5,
		},
		btnText: String,
		overlay: {
			type: Boolean,
			default: true,
		},
		menuStyles: Object,
		buttonClass: Array,
		widthScale: {
			type: Number,
			default: 0.7,
		},
		submenuStyles: [String, Object],
		submenuClass: [String, Object],
		dialogArrow: Boolean,
		iconPosition: {
			type: String,
			default: 'right',
		},
	},
	data() {
		return {
			items: this.$srv('MegaMenu', []),
			hover: false,
			hoverSubmenu: false,
			categoriesWidth: 240,
			close: false,
			visibilityRef: 'megaMenu',
			windowWidth: 0,
		}
	},
	mounted() {
		this.windowWidth = window.innerWidth
		window.addEventListener('resize', this.handleResize)
	},
	watch: {
		$route() {
			this.hover = false
		},
		loading() {
			this.hover = false
		},
	},
	computed: {
		appbarMenuOffset() {
			return this.$srv('AppBarConfig.type') === 'regular' ? 10 : 5
		},
		loading: get('shop/routeLoading'),
		itemsWithSubcategories() {
			return this.items.filter((i) => {
				if (i.linkType == 'link') return false
				else if (i.selectType == 'brand' || i.selectType == 'url') return i.links?.length
				else return (!i.noAutoFillCategory && i.category?.children.length) || i.links?.length
			})
		},
		subChild() {
			return (child) => child.children?.slice(0, 3) || []
		},
		hasManySubChildren() {
			return (child) => child.children?.length > 3
		},
		imageData() {
			return (item) => numberedPropsToArray(item, 2).filter((d) => d.img)
		},
		cSubmenuStyles() {
			let baseStyles = `height: auto; min-height: 100%; max-height: ${this.cMaxHeight}px;`
			if (this.$srv('AppBarConfig.type') === 'round') {
				if (this.$srv('AppBarConfig.roundAppBarMenuLocation') === 'before') {
					return `${baseStyles} width: 952px`
				} else {
					return `${baseStyles} width: 776px`
				}
			} else {
				if (this.$ssr) return
				const offset = 70

				const maxWidth = 835
				const calcWidth = this.windowWidth - this.categoriesWidth - offset
				const width = calcWidth > maxWidth ? maxWidth : calcWidth
				return `${baseStyles} width: ${width}px; left: 100%;`
			}
		},
	},
	methods: {
		handleResize() {
			this.windowWidth = window.innerWidth
		},
		compareFn(elementRect) {
			return elementRect.top > -56 && elementRect.bottom <= window.innerHeight
		},
		hasImages(item) {
			const data = this.imageData(item)
			return data.filter((d) => typeof d.img === 'object').length > 0
		},
		onNotVisibleCb() {
			this.close = true
		},
		showSubmenu(item) {
			if (item.linkType == 'link') return false
			if (item.noAutoFillCategory) return item.links?.length
			return item.category?.children?.length || item.links?.length
		},
	},
	cssVars: {
		selector: '.appbar__mega-menu',
	},
}
</script>

<template>
	<div :ref="visibilityRef" :class="$bem('appbar__mega-menu')" v-if="items.length">
		<Hover
			v-model="hover"
			@input="
				(e) => {
					if (closeOnScroll) close = !e
				}
			"
			:open-delay="$b.d ? 250 : 0"
			:close-delay="$b.d ? 250 : 0"
		>
			<div
				:class="$bem('__container')"
				class="full-height d-flex"
				style="position: relative; max-width: 1200px"
			>
				<Button
					ref="button"
					:class="[$bem('__button'), ...buttonClass]"
					class="px-3 py-4 base"
					:style="noBg && 'background: transparent!important'"
					height="100%"
					large
					tile
					depressed
					@click="hover = !hover"
				>
					<div class="d-flex" style="gap: 7px" :class="hamburgerBtn && 'flex-row-reverse align-center'">
						<v-icon
							v-if="iconPosition == 'left'"
							:class="hover && 'appbar__mega-menu__button-icon--open'"
							class="appbar__mega-menu__button-icon mx-0"
							:left="!hamburgerBtn"
							:size="30"
							:color="hamburgerBtn ? $vars.basetext : ''"
							>{{ hamburgerBtn ? 'mdi-menu' : 'mdi-chevron-down' }}
						</v-icon>
						<span v-if="btnText" class="font-1 appbar__mega-menu__button-text font-weight-bold">
							{{ btnText }}
						</span>
						<v-icon
							v-if="iconPosition == 'right'"
							:class="hover && 'appbar__mega-menu__button-icon--open'"
							class="appbar__mega-menu__button-icon mx-0"
							:left="!hamburgerBtn"
							:size="30"
							:color="hamburgerBtn ? $vars.basetext : ''"
							>{{ hamburgerBtn ? 'mdi-menu' : 'mdi-chevron-down' }}
						</v-icon>
					</div>
				</Button>

				<div
					class="appbar__mega-menu__main elevation-12"
					:class="{ 'dialog-arrow': dialogArrow, 'arrow-offset-left': dialogArrow && btnText }"
					v-show="!close && hover"
					:style="{ ...menuStyles, width: `${categoriesWidth}px`, 'max-height': `${cMaxHeight}px` }"
					style="transition: 0.2s 1s"
				>
					<CategoriesMegaMenu-CategoriesList
						:style="`max-height: ${cMaxHeight}px`"
						:items="items"
						@mouseover="hoverSubmenu = arguments[0]"
						:hover-submenu="hoverSubmenu"
					/>

					<div>
						<!-- Right submenu -->
						<hook zone="submenu">
							<div
								v-for="item of itemsWithSubcategories"
								:key="item.posId"
								v-show="hoverSubmenu === item.posId"
							>
								<div
									v-if="showSubmenu(item)"
									class="appbar__mega-menu__submenu elevation-12 px-6 py-1 rounded rounded-l-0"
									:class="submenuClass"
									:style="cSubmenuStyles"
								>
									<!-- Title -->
									<div style="min-height: 56px">
										<CategoriesMegaMenu-CategoryTitle
											v-if="!item.disableTitle"
											style="border-bottom: 1px solid #eee"
											:item="item"
										/>
									</div>

									<div class="d-flex pl-4">
										<!-- Links -->
										<v-col :cols="hasImages(item) && 9" class="px-2 d-flex flex-column w100 h100">
											<div class="h100" style="min-height: 0; column-count: 3; column-gap: 10px">
												<!-- All links sorted -->
												<div
													class="appbar__mega-menu__children-container pr-2 pb-4"
													style="break-inside: avoid-column"
													v-for="(orderedItem, i) of item.ordered"
													:key="i"
												>
													<div v-if="orderedItem.type == 'link'">
														<div class="font-2 font-weight-bold">
															<SafeLink
																v-if="orderedItem.selectType == 'category'"
																:to="
																	$shop.getShopRoute({
																		categories: orderedItem.category.familyUrlNames,
																	})
																"
															>
																{{ orderedItem.category.name }}
															</SafeLink>
															<SafeLink
																v-if="orderedItem.selectType == 'brand'"
																:to="
																	$shop.getShopRoute({
																		brand: orderedItem.brand.urlName,
																	})
																"
															>
																{{ orderedItem.brand.name }}
															</SafeLink>
															<SafeLink v-else :to="orderedItem.link">
																{{ orderedItem.name }}
															</SafeLink>
														</div>
														<div v-for="(child, n) of orderedItem.children" :key="n">
															<SafeLink
																v-if="child.selectType == 'category'"
																:to="
																	$shop.getShopRoute({
																		categories: child.category.familyUrlNames,
																	})
																"
															>
																{{ child.category.name }}
															</SafeLink>
															<SafeLink
																v-if="child.selectType == 'brand'"
																:to="
																	$shop.getShopRoute({
																		brand: child.brand.urlName,
																	})
																"
															>
																{{ child.brand.name }}
															</SafeLink>
															<SafeLink v-else :to="child.link">
																{{ child.name }}
															</SafeLink>
														</div>
													</div>
													<div v-else>
														<SafeLink
															class="font-weight-bold font-2"
															:to="
																$shop.getShopRoute({
																	categories: orderedItem.familyUrlNames,
																})
															"
														>
															{{ orderedItem.name }}
														</SafeLink>
														<SafeLink
															v-for="(subChild, i) in subChild(orderedItem)"
															:key="i"
															:to="$shop.getShopRoute({ categories: subChild.familyUrlNames })"
														>
															{{ subChild.name }}
														</SafeLink>
														<SafeLink
															:to="
																$shop.getShopRoute({ categories: orderedItem.familyUrlNames })
															"
															class="font-1"
															v-if="hasManySubChildren(orderedItem)"
														>
															<span>Ver más</span>
														</SafeLink>
													</div>
												</div>
											</div>
										</v-col>
										<!-- Images -->
										<v-col class="pl-0" v-if="hasImages(item)">
											<div>
												<div
													v-for="(data, n) in imageData(item)"
													class="py-2"
													:key="n"
													:class="dense && 'appbar__mega-menu__submenu__images-container--dense'"
												>
													<CategoriesMegaMenu-Image v-bind="data" />
												</div>
											</div>
										</v-col>
									</div>
								</div>
							</div>
						</hook>
					</div>
				</div>
			</div>
		</Hover>
	</div>
</template>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.bordered {
	border: 1px solid #eee;
}

.menu-right-icon-active {
	font-size: 30px !important;
	transform: translateX(96%) rotate(0.5turn);
}

.dialog-arrow::before {
	content: '';
	width: 0;
	height: 0;
	border: 0 solid transparent;
	border-right-width: 14px;
	border-left-width: 14px;
	border-bottom: 14px solid var(--primary);
	top: -14px;
	left: 20px;
	position: absolute;
	display: block;
}

.arrow-offset-left::before {
	left: 7px;
}

.appbar__mega-menu {
	&__container {
		position: relative;
		z-index: 3;
	}

	&__button {
		height: 100%;

		&-icon {
			&--open {
				transform: rotate(180deg);
			}
		}
	}

	&__main {
		position: absolute;
		top: 98%;
		z-index: 4;
	}

	&__submenu {
		position: absolute;
		z-index: 5;
		top: 0;
		left: 95%;
		background: var(--secondary) !important;
		height: 100%;
		overflow: overlay;

		&__images-container--dense {
			display: flex;
			justify-content: center;
			gap: 35px;
		}

		&::-webkit-scrollbar {
			width: 3px;
		}

		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 6px rgba(145, 144, 144, 0.3);
		}

		&::-webkit-scrollbar-thumb {
			background-color: var(--base);
			outline: 1px solid rgb(143, 143, 144);
		}

		a {
			color: var(--linktext) !important;
		}
	}

	@media screen and (min-width: 1904px) {
		&__submenu {
			width: 1200px;
		}
	}

	&__children-container {
		overflow: hidden;

		a {
			opacity: 0.65;
			transition: all 0.2s;
			display: block;
			overflow: hidden;
			width: 100%;

			&:hover {
				opacity: 1;
			}

			.v-icon {
				width: 0px;
				opacity: 0;
				transition: all 0.2s;
				display: inline-block;
				vertical-align: middle;
			}

			span {
				width: calc(100% - 25px);
				display: inline-block;
				vertical-align: middle;
			}
		}
	}
}
</style>

