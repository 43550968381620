<script>
export default {
	props: {
		noIcon: Boolean,
		custom: Object,
		iconStyle: [String, Object],
		inputStyle: [String, Object],
	},
	data() {
		return {
			inputText: null,
			results: {},
			searchId: 0,
			topSalesData: null,
			loadingTopSales: false,
			loading: false,
			lastSearchQuery: null,
			usePreFilter: false,
			focused: false,
			hovered: false,
			searchHistory: [],
			lastSearchResultsIds: [],
			maxHeight: 0,
			visibilityRef: 'form',
		}
	},
	computed: {
		searchResultConfig() {
			return this.$srv('SearchResultConfig')
		},
		preFilterConfig() {
			return this.$srv('PreFilterConfig')
		},
		listId() {
			return 'quick_search_results'
		},
		searchQuery() {
			let searchQuery = String(this.inputText || '').trim()
			return searchQuery.length < 2 ? '' : searchQuery
		},
		visible() {
			return this.focused || this.hovered
		},
		slotsParams() {
			return {
				open: this.open,
				close: this.close,
				listId: this.listId,
				results: this.results,
				searchQuery: this.searchQuery,
				searchHistory: this.searchHistory,
				loading: this.loading,
			}
		},
	},
	watch: {
		searchQuery(value) {
			this.searchId += 1
			if (value && this.visible) this.runQuickSearch(value)
		},
		visible(value) {
			if (this.$b.mt) {
				document.querySelector('html').style.overflow = value ? 'hidden' : ''
			}
			this.$emit('visible:change', value)
			if (value && this.searchQuery) this.runQuickSearch(this.searchQuery)
			if (value && this.searchResultConfig && !this.topSalesData) this.fetchTopSales().catch(() => null)
		},
		loading(value) {
			if (value) {
				this.$nextTick(() => {
					this.$refs.list?.$el.scrollTo(0, 0)
				})
			}
		},
	},
	methods: {
		open(search = null) {
			this.$refs.input.focus()
			this.focused = true
			if (search) this.inputText = search
		},
		close() {
			this.$refs.input.blur()
			this.focused = false
			this.hovered = false
		},
		onSubmit() {
			if (!this.searchQuery) return
			if (this.custom?.onSubmit) this.custom.onSubmit(this.searchQuery)
			else this.goShopWithSearchQuery()
		},
		goShopWithSearchQuery() {
			this.$shop.search(this.searchQuery, { usePreFilter: this.usePreFilter })
		},
		runQuickSearch(searchQuery) {
			if (this.searchQuery == this.lastSearchQuery) {
				this.loading = false
				return
			}

			this.loading = true
			const searchId = this.searchId
			setTimeout(async () => {
				if (searchId != this.searchId) return
				const results = await this.fetchQuickSearchResults(searchQuery)
				if (searchId != this.searchId || !results) return
				this.results = {}
				this.$assign(this.results, results)
				this.onQuickSearchSuccess(searchQuery, results)
			}, 500)
		},
		async fetchTopSales() {
			return await this.$shopApi.get({
				url: '/catalog/top-sales',
				loading: (v) => (this.loadingTopSales = v),
				onSuccess: ({ data }) => {
					this.topSalesData = data
				},
			})
		},
		async fetchQuickSearchResults(searchQuery) {
			if (this.custom?.fetchQuickSearchResults) {
				return await this.custom.fetchQuickSearchResults(searchQuery)
			}

			return await this.$shopApi
				.post({
					url: '/catalog/quick-search',
					data: {
						search: searchQuery,
					},
				})
				.then(({ success, data }) => {
					return success ? { products: data.products } : null
				})
		},
		onQuickSearchSuccess(searchQuery, results) {
			this.lastSearchQuery = searchQuery
			this.loading = false

			this.$eventer().trigger('search:search', {
				searchTerm: searchQuery,
			})

			if (results.products?.length) {
				let resultsIds = results.products
					.map((product) => product.id)
					.sort((a, b) => (a > b ? 1 : -1))
					.join('|')

				let replaceLast = resultsIds == this.lastSearchResultsIds
				this.saveNewHistory(searchQuery, replaceLast)
				this.lastSearchResultsIds = resultsIds

				this.$eventer().trigger('search:view-results', {
					products: results.products,
					listId: this.listId,
					searchTerm: searchQuery,
				})
			}

			this.custom?.onQuickSearchSuccess?.(searchQuery, results)
		},
		loadHistory() {
			this.searchHistory = JSON.parse(window.localStorage.getItem('searchHistory') || '[]')
		},
		saveNewHistory(searchQuery, replaceLast = false) {
			if (replaceLast) this.searchHistory.shift()
			this.searchHistory = this.searchHistory.filter((item) => item != searchQuery)
			this.searchHistory.unshift(searchQuery)
			this.searchHistory = this.searchHistory.slice(0, 5)
			window.localStorage.setItem('searchHistory', JSON.stringify(this.searchHistory))
		},
		updateFromRoute() {
			let searchParam = this.$route.query?.search?.trim()
			if (searchParam) this.inputText = searchParam
		},
	},
	eventsListeners: {
		focusMobileSearch() {
			setTimeout(() => this.open(), 200)
		},
		'shop-list:loaded'() {
			this.updateFromRoute()
			this.close()
		},
	},
	mounted() {
		this.updateFromRoute()
		this.loadHistory()
		this.maxHeight = window.innerHeight * (this.$b.m ? 0.6 : 0.8)
	},
	cssVars: {
		selector: '.search-results',
	},
}
</script>
<template>
	<v-hover v-model="hovered" open-delay="150">
		<form class="ac-search-field appbar" @submit.prevent="onSubmit">
			<div class="input-container link d-flex align-center">
				<input
					ref="input"
					autocomplete="off"
					:value="inputText"
					@input="(event) => (inputText = event.target.value)"
					type="search"
					class="pl-3 w100 font-2"
					name="search"
					@focus="focused = true"
					@blur="focused = false"
					placeholder="¿Qué estás buscando?"
				/>

				<div v-if="preFilterConfig" class="d-flex align-content-center justify-end pr-2">
					<v-checkbox
						dense
						v-model="usePreFilter"
						:color="$vars.warning"
						class="flex-shrink-0 px-2 mt-0 pt-0"
						hide-details
					>
						<template #label>
							<span :class="$b.m && 'font-1'" class="pb-1">{{ preFilterConfig.label }}</span>
						</template>
					</v-checkbox>
				</div>

				<div
					v-if="!noIcon"
					class="icon-container pointer flex-shrink-0 px-2 h100 d-flex align-center justify-center"
					@click="onSubmit()"
				>
					<v-icon size="30" class="icon" :style="iconStyle">mdi-magnify</v-icon>
				</div>
			</div>

			<css-vars :default-vars="$vars" selector=".search-results">
				<div class="quick-search pt-1" v-if="visible">
					<div class="search-results quick-search__results elevation-16 rounded overflow-hidden link">
						<v-list ref="list" dense :style="`max-height: ${maxHeight}px; overflow: auto`">
							<template v-if="!searchQuery && !searchHistory.length">
								<slot name="initial-content" v-bind="slotsParams">
									<div
										class="d-flex flex-column align-center justify-center pa-6"
										style="user-select: none; cursor: pointer"
										@click="open()"
									>
										<v-icon large>mdi-magnify</v-icon>
										<v-subheader>Empieza a escribir para buscar</v-subheader>
									</div>
								</slot>
							</template>

							<div v-if="searchResultConfig && !inputText">
								<div v-if="loadingTopSales" class="pa-4">
									<v-progress-circular indeterminate width="2" size="26" />
								</div>
								<div v-else>
									<div v-if="topSalesData.categories && topSalesData.categories.length">
										<v-subheader>{{
											searchResultConfig.topSalesCategoriesText || 'Categorías del momento'
										}}</v-subheader>
										<div class="d-flex align-center flex-wrap px-4">
											<v-chip
												v-for="(category, index) of topSalesData.categories"
												:key="`category-${index}`"
												:to="$shop.getShopRoute({ categories: category.familyUrlNames })"
												class="autocomplete-chip mr-2 mb-2"
											>
												{{ category.familyNames.slice(-2).join(' > ') }}
											</v-chip>
										</div>
									</div>
									<div v-if="topSalesData.brands && topSalesData.brands.length">
										<v-subheader>{{
											searchResultConfig.topSalesBrandsText || 'Marcas del momento'
										}}</v-subheader>
										<div class="d-flex align-center flex-wrap px-4">
											<v-chip
												v-for="(brand, index) of topSalesData.brands"
												:key="`brand-${index}`"
												:to="$shop.getShopRoute({ brand: brand.urlName })"
												class="autocomplete-chip mr-2 mb-2"
											>
												{{ brand.name }}
											</v-chip>
										</div>
									</div>
									<div v-if="topSalesData.products && topSalesData.products.length">
										<v-subheader>{{
											searchResultConfig.topSalesProductsText || 'Productos del momento'
										}}</v-subheader>
										<AutocompleteSearchField-ProductItem
											v-for="(product, listIndex) of topSalesData.products"
											:key="listIndex"
											v-bind="{
												product,
												listId: 'top-sales',
												listIndex,
											}"
											@card:click="close()"
										/>
									</div>
								</div>
							</div>

							<slot name="before-products" v-bind="slotsParams" />
							<template v-if="searchQuery">
								<div v-if="loading" class="pa-4">
									<v-progress-circular indeterminate width="2" size="26" />
								</div>
								<template v-else-if="results.products && results.products.length">
									<v-subheader>Resultados para "{{ searchQuery }}"</v-subheader>
									<slot name="products" v-bind="slotsParams">
										<AutocompleteSearchField-ProductItem
											v-for="(product, listIndex) of results.products"
											:key="listIndex"
											v-bind="{ product, listId, listIndex }"
											@card:click="close()"
										/>
									</slot>
									<v-list-item
										@click="goShopWithSearchQuery()"
										class="d-flex justify-center align-center"
									>
										<div class="text-decoration-underline" style="color: var(--strongtext)">
											Ver todos los resultados
										</div>
									</v-list-item>
								</template>
								<div v-else>
									<v-subheader>
										<v-icon small left>mdi-emoticon-sad-outline</v-icon>
										No encontramos resultados para tu búsqueda
									</v-subheader>
									<v-list-item @click="$shop.goShop()" class="d-flex justify-center align-center">
										<div class="text-decoration-underline" style="color: var(--strongtext)">
											Ver todos los productos
										</div>
									</v-list-item>
								</div>
							</template>

							<slot name="after-products" v-bind="slotsParams" />

							<template v-if="searchHistory.length">
								<v-subheader>Tus búsquedas recientes</v-subheader>
								<v-list-item-group>
									<v-list-item
										dense
										v-for="(search, index) of searchHistory"
										:key="index"
										@click="open(search)"
										class="common-link"
									>
										<v-list-item-icon>
											<v-icon>mdi-history</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											{{ search }}
										</v-list-item-content>
									</v-list-item>
								</v-list-item-group>
							</template>

							<slot name="after-history" v-bind="slotsParams" />
						</v-list>
					</div>
				</div>
			</css-vars>
		</form>
	</v-hover>
</template>

<style scoped lang="scss">
.ac-search-field {
	position: relative;

	::v-deep .v-subheader {
		font-size: 1rem !important;
		padding: 0 16px;
	}
	::v-deep .v-list-item::before {
		opacity: 0 !important;
	}
	::v-deep .v-list-item:hover::before {
		opacity: 0.04 !important;
	}
	::v-deep .v-label {
		white-space: nowrap;
	}

	.input-container {
		border-radius: 0.5rem;
		border: 1px solid var(--linktext);
		overflow: hidden;
		height: 100%;
		width: 100%;
	}

	input {
		outline: none;
		color: var(--strongtext);
	}

	::v-deep input::placeholder {
		color: var(--strongtext);
	}

	.quick-search {
		width: 100%;
		position: absolute;
		left: 0;
		bottom: 0;
		transform: translateY(100%);
		z-index: 100;
	}

	.icon {
		color: var(--strongtext);
	}
}

.v-subheader,
::v-deep .v-subheader {
	color: var(--primary) !important;
}

::v-deep .search-results .v-list {
	background: var(--base);
}

::v-deep .v-chip {
	background: var(--cta) !important;
	border: 1px solid var(--offer) !important;
	color: var(--ctatext) !important;
	&:hover {
		background: var(--secondary) !important;
	}
}

::v-deep .common-link {
	.v-list-item {
		&__icon:first-child {
			margin-right: 10px;
		}
		&__icon .v-icon {
			color: var(--linktext);
		}
		&__content {
			color: var(--link);
		}
	}
}
</style>

